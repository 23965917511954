<div class="d-flex flex-column justify-content-center align-items-center">
  <img class="fut-emoji" src="../../../../assets/emoji/phone.png" />
  <h1 class="fut-h1 mt-1">Inserisci il codice di verifica</h1>
  <span class="text-center">Hai ricevuto il codice di {{ OTP_LENGTH }} cifre sul tuo numero di telefono</span>
  @if (data.error) {
    <span class="text-center fut-text-error">{{ data.error | transloco }}</span>
  }
  <form [formGroup]="formGroup" (submit)="sendCode()">
    <fut-otp-input formControlName="otp"></fut-otp-input>
    <div *transloco="let translate" class="d-flex justify-content-center justify-content-md-end actions">
      <button class="fut-btn fut-btn-link" [disabled]="wait_until !== 0" type="button" (click)="resend()">
        {{ translate('login.otp.action.retry') }}
        @if (wait_until !== 0) {
          <span>({{ wait_until }})</span>
        }
      </button>
      <button class="fut-btn fut-btn-accent" type="submit">
        {{ translate('login.otp.action.confirm') }}
      </button>
    </div>
  </form>
</div>
